import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NumberFormatPipe} from './pipes/number-format.pipe';
import { SearchbarComponent } from '../searchbar/searchbar.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'; // Cambiar aquí
import { MatDatepickerModule } from '@angular/material/datepicker'; // Cambiar aquí



@NgModule({
  declarations: [
    NumberFormatPipe,
    SearchbarComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
  ],
  exports: [
    NumberFormatPipe,
    SearchbarComponent,
  ]
})
export class SharedModule { }
