import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private router: Router,
    private auth: AngularFireAuth
  ) { }

  canActivate(): Promise<boolean> {
    return this.isAuthenticated().then(user => {
      return true;
    })
      .catch(err => {
        // this.router.navigate(['sesion/inicio']);
        console.log('isAuthenticated error', err);
        return false;
      });
  }


  isAuthenticated(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // Primero checamos que usuario se encuentra en auth
      this.auth.onAuthStateChanged( user => {
        if (user) {
          /*if (user.emailVerified){
            console.log('el usuario existe', user);
            resolve(user);
          }else{
            // this.router.navigate(['sesion/inicio']);
            reject(Error('El usuario no está verificado'));
          }*/
          console.log('el usuario está autenticado', user);
          // this.router.navigate(['home/app-users']);
          resolve(user);
        } else {
          // Si el usuario no está en auth inmediatamente lo mando a registro
          // this.router.navigate(['sesion/inicio']);
          reject(Error('El usuario no ha encontrado'));
        }
      });
    });
  }
}
