import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private lStorage = localStorage;
  private sStorage = sessionStorage;

  constructor() { }

  readLocalStorage(key: string): any {
    try {
      const value = this.lStorage.getItem(key);
      return (value) ? JSON.parse(atob(value)) : null;
    } catch {
      return null;
    }
  }

  saveLocalStorage(key: string, value: any, base64: boolean = false):void {
    this.lStorage.setItem( key, (base64) ? value : btoa( JSON.stringify(value) ) );
  }

  destroyItemLocalStorage(key: string): void {
    this.lStorage.removeItem(key);
  }

  clearAllLocalStorage(): void {
    this.lStorage.clear();
  }

  readSessionStorage(key: string): any {
    try {
      const value = this.sStorage.getItem(key);
      return (value) ? JSON.parse(atob(value)) : null;
    } catch {
      return null;
    }
  }

  saveSessionStorage(key: string, value: any, base64: boolean = false):void {
    this.sStorage.setItem( key, (base64) ? value : btoa( JSON.stringify(value) ) );
  }

  destroyItemSessionStorage(key: string): void {
    this.sStorage.removeItem(key);
  }

  clearAllSessionStorage(): void {
    this.sStorage.clear();
  }

}
