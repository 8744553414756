import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AngularFireStorage } from "@angular/fire/storage";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProviderId } from '../core/enums';

@Injectable({
  providedIn: 'root'
})
export class AppUsersService {

  private url = 'users';
  private urlReservations = 'reservations';
  private urlTransfers = 'transfers_tickets';
  private urlUserEvents = 'saved_events';
  private urlEvents = 'events';
    
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) { }

  /**
   * Observable de los usaurios de app móvil.
   */
  getUsers(): Observable<any[]> {
    return this.firestore.collection(this.url).valueChanges();
  }

  /**
   * Obtener los datos de un solo usuario
   */
  getUser(uid: string): any {
    return this.firestore.collection(this.url).doc(uid).get();
  }
  /**
   * Obtener usuario buscando por su número de teléfono
   */
  userExists(phone: number): Promise<any> {
    return new Promise<any>((resolve, rejects) => {
      this.firestore.collection(this.url).ref.where('phone', '==', phone).get().then((data) => {
        resolve(data.docs.length > 0);
      });
    });
  }
  userExistsByEmail(email: string): Promise<any> {
    return new Promise<any>((resolve, rejects) => {
      this.firestore.collection(this.url).ref.where('email', '==', email).get().then((data) => {
        resolve(data.docs.length > 0);
      });
    });
  }
  userExistsByUid(uid: string): Observable<any> {
    return this.firestore.collection(this.url).doc(uid).get();
  }

  getUserByEmail(email: string): Observable<any> {
    return this.firestore.collection(this.url, ref => ref.where('email', '==', email).orderBy('created_at', 'desc'))
      .get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            const data = doc.data() as any;
            data.id = doc.id;
            return data;
          });
        })
      );
  }

  getUserLoginGoogleWithEmail(email_google: string): Observable<any> {
    return this.firestore.collection(this.url, ref => ref.where('email_google', '==', email_google))
      .get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            const data = doc.data() as any;
            data.id = doc.id;
            return data;
          });
        })
      );
  }

  getUserLoginAppleWithEmail(email_apple: string): Observable<any> {
    return this.firestore.collection(this.url, ref => ref.where('email_apple', '==', email_apple))
      .get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            const data = doc.data() as any;
            data.id = doc.id;
            return data;
          });
        })
      );
  }

  getUserLoginFacebookWithEmail(email_facebook: string): Observable<any> {
    return this.firestore.collection(this.url, ref => ref.where('email_facebook', '==', email_facebook))
      .get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            const data = doc.data() as any;
            data.id = doc.id;
            return data;
          });
        })
      );
  }

  getUserByPhone(phone: string): Observable<any> {
    return this.firestore.collection(this.url, ref => ref.where('phone', '==', phone).orderBy('created_at', 'desc'))
      .get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.docs.map(doc => {
            const data = doc.data() as any;
            data.id = doc.id;
            return data;
          });
        })
      );
  }

  saveTicketTransfer(reservation, transfer): Promise<void> {
    const batch = this.firestore.firestore.batch();
    batch.update(this.firestore.collection(this.urlReservations).doc(reservation.id).ref, {
      selected_seats: reservation.selected_seats
    });
    batch.set(this.firestore.collection(this.urlTransfers).doc(transfer.id).ref, transfer);
    return batch.commit();
  }


  /**
   * Guardar el usuario en base de datos
   */
  async createAccount(form: any, uid: string, gmail: boolean): Promise<boolean> {
    return this.firestore.collection(this.url).doc(uid).set({
        apple: form.apple ? form.apple : false,
        apple_token: form.apple_token ? form.apple_token.length > 0 : '',
        apple_music_token: '',
        birthday: new Date(),
        country: form.country,
        created_at: new Date(),
        deleted_at: null,
        devices: [],
        email: form.email,
        email_google: form.email_google ? form.email_google : "",
        email_apple: form.email_apple ? form.email_apple : "",
        email_facebook: form.email_facebook ? form.email_facebook : "",
        facebook_token: form.facebook_token,
        first_name: form.first_name,
        google: gmail,
        image: form.image ? form.image : '',
        last_name: form.last_name,
        nickname: form.nickname ? form.nickname : '',
        openpay_id: form.openpay_id,
        paypal_token: '',
        phone: form.phone ? form.phone : '',
        spotify_token: '',
        stripe_id: '',
        updated_at: new Date(),
        emailVerified: form.emailVerified ? form.emailVerified : '',
        providerId: form.providerId ? form.providerId : ProviderId.tixygo_web,
        lang: form.lang
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
}

  /**
   * Actualizar número de teléfono del usuario en base de datos
   */
  updatePhone(uid: string, phoneNumber: string) {
    return this.firestore.collection(this.url).doc(uid)
      .update({
        phone: phoneNumber
      });
  }

  /**
   * Actualizar datos de perfil del usuario en base de datos
   */

  saveChanges(form, uid): Promise<void> {
    return this.firestore.collection(this.url).doc(uid)
      .update(form);
  }

  saveChangesWithFile(form, uid, file): Promise<void> {
    if (file) {
      return this.saveImage(uid, file).then(result => {
        // 1.2 poner url en el model de la subcategoría
        form.image = result.src;
        // 1.3 guardar en base de datos
        return this.firestore.collection(this.url).doc(uid)
          .update(form);
      });
    } else {
      return this.firestore.collection(this.url).doc(uid)
        .update(form);
    }
  }

  /**
   *
   * Guardar un archivo en Storage de Firebase
   *
   */
  // tslint:disable-next-line:typedef
  async saveImage(id: string, file: File) {
    const path = `users/${id}`;
    const item = await this.storage.upload(path, file);
    return item.ref.getDownloadURL().then(res => {
      if (res) {
        return {
          code: 200,
          estado: true,
          file: false,
          fileName: file.name,
          msg: 'Success',
          src: res
        };
      }
    });
  }

  saveEvent(uid: string, eventId: string) {
    const savedEvent = {
      event_id: eventId,
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
    }

    return this.firestore.collection(this.url)
      .doc(uid)
      .collection(this.urlUserEvents)
      .doc(eventId)
      .set(savedEvent)
      .then(() => {
        console.log('Evento guardado con ID:', eventId);
      })
      .catch((error) => {
        console.error('Error al guardar el evento:', error);
      });
  }

  deleteEvent(uid: string, eventId: string): Observable<void> {
    const eventRef = this.firestore.collection(this.url).doc(uid)
      .collection(this.urlUserEvents).doc(eventId);

    return from(eventRef.update({ deleted_at: new Date() }));
  }

  getUserEvents(userId: string): Observable<any[]> {
    return this.firestore.collection(this.url)
      .doc(userId)
      .collection(this.urlUserEvents, ref => ref.where('deleted_at', '==', null))
      .valueChanges()
      .pipe(
        switchMap((userEvents: any[]) => {
          const documentObservables: Observable<any>[] = userEvents.map(userEvent =>
            this.firestore.collection(this.urlEvents).doc(userEvent.event_id).valueChanges()
          );

          return combineLatest(documentObservables);
        })
      );
  }

  saveFavoriteEvent(email: string, eventData: any): Promise<void> {
    return this.firestore.collection('users', ref => ref.where('email', '==', email)).get().toPromise()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw new Error('No user found with the provided email');
        }

        const docId = querySnapshot.docs[0].id;

        const data = {
          id: eventData.id,
          event: eventData
        }
        return this.firestore.collection('users').doc(docId).collection('favorites').doc(eventData.id).set(data);
      });
  }

  getFavorites(userId: string) {
    return this.firestore.collection('users').doc(userId).collection('favorites').valueChanges();
  }

  deleteFavorite(userId: string, eventId: string) {
    return this.firestore.collection('users').doc(userId).collection('favorites').doc(eventId).delete()
      .then(() => {
        console.log("El evento se ha guardado");
      }).catch((e) => {
        console.log(e);
      });
  }

  sendPasswordResetEmail(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Asegúrate de que envías el email como parte de un objeto
      this.http.post(`${environment.firebaseUrl}/send_pass_reset_email`, { email })
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        });
    })
  }

  sendEmailVerification(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_email_verification`, {email})
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
    
  }

  sendChangeEmail(currentEmail: string, newEmail: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_change_email`, { currentEmail, newEmail })
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

}







