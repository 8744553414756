import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  showFooter: boolean = true;

  constructor(
    private router: Router
  ) {
  }
  ngOnInit(): void {
    const route = this.router.url;
    console.log('ROUTE EN LA QUE ME ENCUENTRO', route);
 
   // Llamar a updateFooterState en la carga inicial para establecer showFooter según la ruta actual
   this.updateFooterState();

   // Suscribirse a los eventos de navegación para actualizar el footer en cada cambio de ruta
   this.router.events.subscribe(event => {
     if (event instanceof NavigationEnd) {
       this.updateFooterState();
     }
   });
 }

 // Método para actualizar el estado del footer según la ruta 
 updateFooterState() {
   const currentUrl = this.router.url;
   console.log('ROUTE EN LA QUE ME ENCUENTRO', currentUrl);

   // Lógica para ocultar o mostrar el footer según la ruta
   if (currentUrl.startsWith('/events/event-form')) {
     this.showFooter = false;
   } else if (currentUrl === '/home/main') {
     this.showFooter = true;
   } else {
     this.showFooter = true;
   }
 }

}
