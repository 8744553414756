<!-- Version Mobile -->
<div class="searchbar-input-spacer" [formGroup]="searchForm" *ngIf="isMobile">
    <div class="filter-icons">

        <div class="search-text">
            <img src="../../assets/images/icons/searchIcon.svg" alt="search-icon" class="filter-icon-image">
            <input type="text" formControlName="inputSearchText" (keydown.enter)="applyFilters()" placeholder="¿Qué evento quieres ver en vivo?" />
        </div>

        <div class="filter-icon">
            <img src="../../assets/images/icons/locationIcon.svg" alt="location-icon" class="filter-icon-image">
            <select formControlName="selectedLocation" class="location-select">
                <option value="" disabled selected>Seleccionar ubicación</option>
                <option *ngFor="let place of places" [value]="place">{{ place }}</option>
            </select>
        </div>

        <div class="filter-icon-date" (click)="picker.open()">
            <div class="filter-inline">
                <img src="../../assets/images/icons/dateIcon.svg" alt="calendar-icon" class="filter-icon-image">
                <button mat-icon-button mat-raised-button class="date-button" #dateButton>
                    {{ formattedStartDate && formattedEndDate ? (formattedStartDate + ' - ' + formattedEndDate) : 'Fechas' }}
                </button>
            </div>

            <div class="date-range-input-container">
                <mat-date-range-input [rangePicker]="picker" class="date-range-input">
                    <input matStartDate formControlName="startDate" placeholder="Desde">
                    <input matEndDate formControlName="endDate" placeholder="Hasta">
                </mat-date-range-input>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </div>
        </div>

        <div class="filter-buttons">
            <button (click)="applyFilters()">Buscar</button>
            <button (click)="clearFilters()">Eliminar Filtros</button>
        </div>
    </div>
</div>

<!-- Versión escritorio -->
<div class="searchbar-input-spacer" [formGroup]="searchForm" *ngIf="!isMobile">
    <div class="filter-icons">

        <div class="filter-icon">
            <img src="../../assets/images/icons/locationIcon.svg" alt="location-icon" class="filter-icon-image">
            <select formControlName="selectedLocation" class="location-select">
                <option value="" disabled selected>Seleccionar ubicación</option>
                <option *ngFor="let place of places" [value]="place">{{ place }}</option>
            </select>
            <img src="../../assets/images/icons/arrowIcon.svg" alt="arrow-icon" class="filter-icon-arrow">
        </div>

        <div class="filter-icon-date" (click)="picker.open()">
            <div class="filter-inline">
                <img src="../../assets/images/icons/dateIcon.svg" alt="calendar-icon" class="filter-icon-image">
                <button mat-icon-button mat-raised-button class="date-button" #dateButton>
                    {{ formattedStartDate && formattedEndDate ? (formattedStartDate + ' - ' + formattedEndDate) : 'Seleccionar Fechas' }}
                    <img src="../../assets/images/icons/arrowIcon.svg" alt="arrow-icon" class="filter-icon-arrow">
                </button>
            </div>

            <div class="date-range-input-container">
                <mat-date-range-input [rangePicker]="picker" class="date-range-input">
                    <input matStartDate formControlName="startDate" placeholder="Desde">
                    <input matEndDate formControlName="endDate" placeholder="Hasta">
                </mat-date-range-input>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </div>
        </div>

        <div class="filter-icon search-text">
            <img src="../../assets/images/icons/searchIcon.svg" alt="search-icon" class="filter-icon-image">
            <input type="text" formControlName="inputSearchText" (keydown.enter)="applyFilters()" placeholder="¿Qué evento quieres ver en vivo?" />
        </div>
        
        <div class="filter-buttons">
            <button (click)="applyFilters()">Buscar</button>
            <button (click)="clearFilters()">Eliminar Filtros</button>
        </div>
    </div>
</div>
